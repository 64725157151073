import React from 'react';

function Timeline() {
  return (
<div className='timeline-container'>
        <div className="header">
            <h1>History of Roundnet Philippines</h1>
              <p>Here is a short history of how Roundnet Philippines started.</p>
        </div>
    <div className="timeline">
    {/* Right */}
      <div className="timeline__component">
        <div className="timeline__date timeline__date--right">2015</div>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">The Arrival of Roundnet in the Philippines</h2>
        <p className="timeline__paragraph">
        ⸺ Roundnet, also commonly known as Spikeball, has taken the world by storm as the next great global sport. Countries all over the globe have created a movement to continuously support and celebrate this sport through games and gatherings of players with diverse strengths and abilities that guide in achieving its popularity.</p>
        <p className="timeline__paragraph">
        ⸺ The Philippines, as one of the countries who endlessly contribute in this movement, have marked the presence of Roundnet in the country since the year 2015. The sport has been planted and still grows on Filipino grounds. This part of history has given an opportunity for the Philippines to create a community, that has now become a family, called 'Roundnet Philippines'.
        </p>
      </div>
    {/* Left */}
      <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">Coach Paul and UP Students Pioneer RoundnetPH</h2>
        <p className="timeline__paragraph">
        ⸺ In 2017, Coach Paul, together with students from University of the Philippines, took the initiative to develop 'RoundnetPH,' marking the beginning of our achievements and endeavors to make Roundnet known throughout the country. </p>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component">
        <div className="timeline__date">2017</div>
      </div>
    {/* Right */}
    <div className="timeline__component">
        <div className="timeline__date timeline__date--right">2018</div>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">Hosted Tournament and Growing Recognition</h2>
        <p className="timeline__paragraph">
        ⸺ In the year 2018, RoundnetPH finally hosted its first tournament, "Lingayen Spike-Off: Roundnet Tournament", held in the Philippine Beach Games at Lingayen, Pangasinan. Competitive players and beginners were invited to join and at last, roundnet had its opportunity under the spotlight and was acknowledged by many. </p>
        <p className="timeline__paragraph">
        ⸺ RoundnetPH began to appeal partners such as MB Cove as well as sponsors like Decathlon, Steeze, and Plunge PH, which allowed them to hold more tournaments up until 2019 such as the "Calayo Spike-Off: Roundnet Tournament" and invite more players all over the country.
        </p>
      </div>
    {/* Left */}
    <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">Pioneering Roundnet in School Curriculum</h2>
        <p className="timeline__paragraph">
        ⸺ Filled with fun and exciting experience in different Roundnet Tournaments, Coach Vic was quickly engrossed in the sport and saw the huge potential of it to become the next big thing here in the Philippines. It fueled him to start a movement of his own.  </p>
        <p className="timeline__paragraph">
        ⸺ A school in Fairview Quezon City where coach Vic practice his specialization as a Physical Education teacher, pioneered the adaptation of Roundnet as part of their curriculum in Physical Education and sports programs. With his vision and superb efforts to continuously promote the sport, he managed to go beyond places to reach various schools in Metro Manila and held different Roundnet Experience workshops to educate more students and teachers about the sport. 
        </p>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component">
        <div className="timeline__date">2019 </div>
      </div>
    {/* Right */}
    <div className="timeline__component">
        <div className="timeline__date timeline__date--right">2020</div>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">Roundnet Philippines was officially formed</h2>
        <p className="timeline__paragraph">
        ⸺ Coach Paul and Coach Vic joined forces with Coach RR to continue the mission in supporting the growth of the sport here in the Philippines and to widen the community of Roundnet players.</p>
        <p className="timeline__paragraph">
        ⸺ On January 21, 2020, the first official meeting of the Roundnet Philippines was held in Decathlon Pasig and there, all three coaches, together with other members, finally formed an agreement to officialize the partnership of both RoundnetPH and Roundnet Philippines to work together and have an official Roundnet organization that will continue to innovate and promote the sport in the country.</p>
      </div>
    {/* Left */}
    <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">First Ever Roundnet Philippines Sanctioned Tournament</h2>
        <p className="timeline__paragraph">
        ⸺ In February 2020, Roundnet Philippines organized their first tournament, bringing together players from all over the country. It was a significant step in promoting Roundnet and fostering a competitive community in the Philippines.</p>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component">
        <div className="timeline__date">February 2020</div>
      </div>
        {/* Right */}
        <div className="timeline__component">
        <div className="timeline__date timeline__date--right">2021</div>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">Founding Member of ARF</h2>
        <p className="timeline__paragraph">
        ⸺ RP became one of the founding members of the Asian Roundnet Federation.</p>
      </div>
    {/* Left */}
    <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">Full Member of IRF</h2>
        <p className="timeline__paragraph">
        ⸺ Roundnet Philippines became a full member of the International Roundnet Federation. </p>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component">
        <div className="timeline__date">2022 </div>
      </div>
        {/* Right */}
        <div className="timeline__component">
        <div className="timeline__date timeline__date--right">February 23, 2022</div>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">Roundnet PH, Inc. Registration Approved by SEC</h2>
        <p className="timeline__paragraph">
        ⸺ Roundnet Philippines, Inc. were duly approved by the Securities and Exchange Commission (SEC)</p>
      </div>
    {/* Left */}
    <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">An Emerging Roundnet Community in the South</h2>
        <p className="timeline__paragraph">
        ⸺ Negros Oriental Roundnet Association was formed and is now considered one of the biggest roundnet community in the Philippines.
 </p>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component">
        <div className="timeline__date">Oct 2022 </div>
      </div>
        {/* Right */}
        <div className="timeline__component">
        <div className="timeline__date timeline__date--right">April 2023</div>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">National Roundnet Championships Debut</h2>
        <p className="timeline__paragraph">
        ⸺ 1st National Roundnet Championships (SEC)</p>
        <p className="timeline__paragraph">
        ⸺ 1st ARF Tour Series Philippines
Roundnet Clinic by the 2022 World  (SEC)</p>
        <p className="timeline__paragraph">
        ⸺ Champions Buddy Hammon and Clark Marshall (SEC)</p>
      </div>
    {/* Left */}
    <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">Philippines Excels in ARF Tour Series Taiwan</h2>
        <p className="timeline__paragraph">
        ⸺ Philippines was able to participate in the ARF Tour Series Taiwan that resulted with
 </p>
        <p className="timeline__paragraph">
        ⸺ 2nd Place in Mixed Division (Anrie Pagunsan and Frances Navera)
 </p>
        <p className="timeline__paragraph">
        ⸺ 4th Place in Womens Division (Frances Navera and Maika Solomon)
 </p>
        <p className="timeline__paragraph">
        ⸺ 5th Place in Mens Division (Anrie Pagunsan and Marc Tubio)
 </p>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component">
        <div className="timeline__date">June 2023 </div>
      </div>
        {/* Right */}
        <div className="timeline__component">
        <div className="timeline__date timeline__date--right">2024 onwards</div>
      </div>
      <div className="timeline__middle">
        <div className="timeline__point"></div>
      </div>
      <div className="timeline__component timeline__component--bg">
        <h2 className="timeline__title">A Bright Future for Roundnet in the Philippines</h2>
        <p className="timeline__paragraph">
        ⸺ Roundnet Philippines will indeed have a bright future as both organizations share the same vision as they commit with hard work and engage with full effort to fuel the growth of Roundnet and support, unite, and guide its own community.
        </p>
      </div>
    </div>
    <div className="video-container2">
        <div className="video">
          <iframe width="1280" height="720" src="https://www.youtube.com/embed/ThnENoFOqG0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    </div>
</div>
  );
}

export default Timeline;
