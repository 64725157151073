import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import picture1 from '../assets/victorDomingo.png';
import picture2 from '../assets/kennethLandicho.png';
import picture3 from '../assets/jamesTiambeng.png';
import picture4 from '../assets/mimiLabindao.png';
import picture5 from '../assets/johnAquino.png';
import picture6 from '../assets/paulLibo-on.png';
import picture7 from '../assets/leanneSalvador.png';

const teamData = [
  {
    picture: picture1,
    name: 'Victor Domingo',
    role: 'President',
    description:
      '',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/home',
    instagram: 'https://www.instagram.com/',
  },
  {
    picture: picture2,
    name: 'Kenneth Landicho',
    role: 'Vice-President',
    description:
      '',
      facebook: 'https://www.facebook.com/',
      twitter: 'https://twitter.com/home',
      instagram: 'https://www.instagram.com/',
  },
  {
    picture: picture3,
    name: 'James Tiambeng',
    role: 'Treasurer',
    description:
      '',
      facebook: 'https://www.facebook.com/',
      twitter: 'https://twitter.com/home',
      instagram: 'https://www.instagram.com/',
  },
  {
    picture: picture4,
    name: 'Mimi Labindao',
    role: 'Secretary',
    description:
      '',
      facebook: 'https://www.facebook.com/',
      twitter: 'https://twitter.com/home',
      instagram: 'https://www.instagram.com/',
  },
  {
    picture: picture5,
    name: 'John Aquino',
    role: 'Member',
    description:
      '',
      facebook: 'https://www.facebook.com/',
      twitter: 'https://twitter.com/home',
      instagram: 'https://www.instagram.com/',
  },
  {
    picture: picture6,
    name: 'Pau Libo-on',
    role: 'Member',
    description:
      '',
      facebook: 'https://www.facebook.com/',
      twitter: 'https://twitter.com/home',
      instagram: 'https://www.instagram.com/',
  },
  {
    picture: picture7,
    name: 'Leanne Salvador',
    role: 'Member',
    description:
      '',
      facebook: 'https://www.facebook.com/',
      twitter: 'https://twitter.com/home',
      instagram: 'https://www.instagram.com/',
  },
];

function MeetTheTeam() {
  return (
  <div className='card-containerr'>
    <div className="card-header">
      <h1>RP Board Members</h1>
      <p>(2022-2023)</p>
    </div>
    <div className="card-container">
      {teamData.map((member, index) => (
        <div className="card" key={index}>
          <div className="card-image">
            <img src={member.picture} alt="profile-image" />
          </div>
          <p className="name">{member.name}</p>
          <p>{member.role}</p>
          <p>{member.description}</p>
          <div className="socials">
            <button className="facebook" onClick={() => window.open(member.facebook, "_blank")}>
              <FaFacebook />
            </button>
            <button className="twitter" onClick={() => window.open(member.twitter, "_blank")}>
              <FaTwitter />
            </button>
            <button className="instagram" onClick={() => window.open(member.instagram, "_blank")}>
              <FaInstagram />
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
}

export default MeetTheTeam;
