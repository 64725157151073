import "../scss/index.scss";
import { Footer, ScrollToTop } from "../components";
import HeaderLearn from "../components/headers/HeaderLearn";
import AboutRoundnet from "../components/AboutRoundnet";
import FAQ from "../components/FAQ";
import DownloadRules from "../components/DownloadRules";
import Transition from "../Transition";
import RegisterWorkshop from "../components/RegisterWorkshop";

function Learn() {


  return (
    <div className="App">
      <ScrollToTop />
      <HeaderLearn />
      <AboutRoundnet />
      <FAQ />
      <RegisterWorkshop />
      <DownloadRules />
      <Footer />
    </div>
  );
}

export default Transition(Learn);
