import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import SwiperCore, { Navigation, EffectCoverflow, Pagination } from 'swiper';
import 'swiper/components/pagination/pagination.min.css';
import video1 from '../assets/reels_1.mp4';
import video2 from '../assets/reels_2.mp4';
import video3 from '../assets/reels_3.mp4';
import video4 from '../assets/reels_4.mp4';
import video5 from '../assets/reels_5.mp4';
import video6 from '../assets/reels_6.mp4';
import video7 from '../assets/reels_7.mp4';
import video8 from '../assets/reels_8.mp4';
import video9 from '../assets/reels_9.mp4';
import video10 from '../assets/reels_10.mp4';


SwiperCore.use([Navigation, EffectCoverflow, Pagination]);

const Reel = () => {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [userInteracted, setUserInteracted] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const videos = useMemo(() => [video1, video2, video3, video4, video5, video6, video7, video8, video9, video10,], []);

  const handleVideoPlay = (index) => {
    setActiveIndex(index);
    if (swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  const handleVideoClick = (index) => {
    if (userInteracted) {
      const videoElement = document.getElementById(`video-${index}`);
      if (videoElement) {
        if (videoElement.paused) {
          videoElement.play().catch((error) => {
            console.log('Play failed:', error);
            setVideoPlaying(false);
          });
        } else {
          videoElement.pause();
        }
      }
    }
  };
  

  const handleDocumentClick = () => {
    setUserInteracted(true);
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, []);

  useEffect(() => {
    const handleVideoVisibility = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVideoPlaying(true);
        } else {
          setVideoPlaying(false);
        }
      });
    };

    const options = {
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleVideoVisibility, options);
    const swiperElement = swiperRef.current;
    if (swiperElement) {
      observer.observe(swiperElement);
    }

    return () => {
      if (swiperElement) {
        observer.unobserve(swiperElement);
      }
    };
  }, []);

  useEffect(() => {
    videos.forEach((video, index) => {
      const videoElement = document.getElementById(`video-${index}`);
      if (videoElement) {
        if (index !== activeIndex || !videoPlaying) {
          videoElement.pause();
          videoElement.muted = true;
        } else {
          videoElement.muted = false;
          videoElement.play().catch((error) => {
            console.log('Autoplay failed:', error);
            setVideoPlaying(false);
          });
        }
      }
    });
  }, [activeIndex, videos, videoPlaying]);

  return (
    <>
      <div className="swiper-swiper" ref={swiperRef}>
        <div className="header-val">
          <div className="title-about">Featured</div>
          <div className="subtitle">Engaging, Informative, Visual, Captivating, Short</div>
        </div>
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView="auto"
          coverflowEffect={{
            rotate: 50,
            stretch: -70,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          navigation
          pagination={{ clickable: true }}
          onSlideChange={(swiper) => handleVideoPlay(swiper.activeIndex)}
          ref={swiperRef}
        >
          {videos.map((video, index) => (
            <SwiperSlide key={index}>
              <div className="video-container">
                <video
                  id={`video-${index}`}
                  controls={false}
                  style={{ width: '100%', height: '100%' }}
                  autoPlay={videoPlaying && index === activeIndex}
                  loop
                  muted
                  onClick={() => handleVideoClick(index)}
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Reel;
