import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LoadingPage from './components/LoadingPage';
import Landing from './pages/Landing';
import Events from './pages/Events';
import Learn from './pages/Learn';
import Gallery from './pages/Gallery';
import JoinUs from './pages/JoinUs';
import CircleCursor from "./components/CircleCursor";
import AboutUs from './pages/AboutUs';
import Rankings from './pages/Rankings';
import Membership from './pages/Membership';
import { AnimatePresence } from 'framer-motion';

function App() {
  useEffect(() => {
    document.title = 'Roundnet Philippines';
  }, []);
  
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    // Simulate a delay to show the loading page
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Change the delay duration as per your requirement
  }, []);
  
  

  return (
    <div>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Router>
          <AnimatedRoutes />
        </Router>
      )}
    </div>
  );
}

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <div className="animated-route-container">
      <AnimatePresence mode="wait">
      <CircleCursor />
      <Routes location={location} key={location.pathname}>
        <Route index element={<Landing />} />
        <Route path="/events" element={<Events />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/ranking" element={<Rankings />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/join" element={<JoinUs />} />
      </Routes>
    </AnimatePresence>
    </div>
  );
};

export default App;
