import React, { useState } from 'react';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'Q1: What is Roundnet?',
      answer: 'Roundnet, also known as Spikeball, is an exciting and fast-paced sport played with teams of two. It involves bouncing a ball off a circular net, aiming to make it challenging for the opposing team to return the ball within three touches. It is a great way to stay active, enhance coordination, and have fun with friends and family.',
    },
    {
      question: 'Q2: How can I get involved in Roundnet in the Philippines?',
      answer: (
        <span>
          To get involved, you can either apply for membership through our membership application or{' '}
          <a href="https://roundnetph.com/join" target="_blank" rel="noreferrer">by clicking here</a> to access various engagement opportunities and resources.
        </span>
      ),
    },
    {
      question: 'Q3: Are there any membership fees to join Roundnet Philippines?',
      answer: 'No, there are no membership fees to join Roundnet Philippines. We aim to make the sport accessible to as many people as possible, and our community is open to anyone interested in playing Roundnet, regardless of skill level or experience. However, certain tournaments or events may have participation fees, which will be communicated in advance.',
    },
    {
      question: 'Q4: Where can I purchase Roundnet equipment in the Philippines?',
      answer: (
        <span>
          In the Philippines, you can conveniently purchase Roundnet equipment, including Roundnet balls and nets, from our{' '}
          <a href="https://shopee.ph/rumbleroundnetph" target="_blank" rel="noreferrer">Shopee page</a>.
        </span>
      ),
    },
    {
      question: 'Q5: Are there any age restrictions to participate in Roundnet?',
      answer: 'Roundnet is a sport suitable for people of all ages! Whether you are a young child, a teenager, an adult, or even a senior citizen, you can enjoy playing Roundnet and participate in tournaments. It is a great activity for family gatherings, team-building events, or simply having fun with friends.',
    },
    {
      question: 'Q6: Can I host a Roundnet tournament or event in my local area?',
      answer: (
        <span>
          Absolutely, you can host a Roundnet tournament or event in your local area by signing up for a sanctioned tournament through{' '}
          <a href="https://roundnetph.com/join" target="_blank" rel="noreferrer">Sanction Tournmanet</a>.
        </span>
      ),
    },
  ];
  

  return (
    <div className="faq-section-container">
      <div className="faq-section">
            <h2 className="faq-section__title">Frequently Asked Questions</h2>
            <p className="faq-section__subtitle">Quickly find out if we've already addressed your query</p>
        <div className="faq-section__accordion">
          {faqData.map((item, index) => (
            <div
              key={index}
              className={`faq-section__item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleToggle(index)}
            >
              <div className="faq-section__question">
                {item.question}
                <span className={`faq-section__arrow ${activeIndex === index ? 'active' : ''}`}>&#62;</span>
              </div>
              <div className="faq-section__answer">
                <p className="faq-section__answer-text">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
