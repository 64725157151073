import "../scss/index.scss";
import { Footer, ScrollToTop} from "../components";
import HeaderTeam from "../components/headers/HeaderTeam";
import Clubs from "../components/Clubs";
import RegisterClub from "../components/RegisterClub";
import PendingClubs from "../components/PendingClubs";
import Transition from "../Transition";

function Membership() {

  return (
    <div className="App">
      <ScrollToTop />
      <HeaderTeam />
      <Clubs />
      <PendingClubs />
      <RegisterClub />
      <Footer />
    </div>
  );
}

export default Transition(Membership);
