import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { FaSearch } from 'react-icons/fa';


const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#D02129',
      color: "#fff"
    }
  },
  headCells: {
    style: {
      fontSize: '13px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  },
  cells: {
    style: {
      fontSize: '12px',
      color: "black"
    },
  },
};

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#1d1d1e',
  },
  divider: {
    default: '#7B7B7B',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

function DivisionRanking3() {
  const columns = [
    {
      name: "Rank",
      selector: row => parseInt(row["Rank"]),
      sortable: true,
    },
    {
      name: "First Name",
      selector: row => row["First Name"],
      sortable: true,
    },
    {
      name: "Last Name",
      selector: row => row["Last Name"],
      sortable: true,
    },
    {
      name: "TOTAL POINTS",
      selector: row => parseInt(row["TOTAL POINTS"]),
      sortable: true,
    },
    {
      name: "Bunal Ta Bai",
      selector: row => parseInt(row["Bunal Ta Bai"]),
      sortable: true,
    },
    {
      name: "Oriental Spike",
      selector: row => parseInt(row["Oriental Spike"]),
      sortable: true,
    },
    {
      name: "ARF PH",
      selector: row => parseInt(row["ARF PH (x1.75)"]),
      sortable: true,
    },
    {
      name: "ARF Taiwan",
      selector: row => parseInt(row["ARF Taiwan (x1.75)"]),
      sortable: true,
    },
  ];
  
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
	const [pending, setPending] = React.useState(true);
	const [rows, setRows] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://sheetdb.io/api/v1/40ip6jaaqa6rf');
        setRecords(response.data);
        setFilteredRecords(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const newData = records.filter(
      (row) =>
        row["First Name"].toLowerCase().includes(searchTerm) ||
        row["Last Name"].toLowerCase().includes(searchTerm)
    );
    setFilteredRecords(newData);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(filteredRecords);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
  

  return (
    <div className='data-table'>
       <div className="centered-content">
        <h1 style={{ color: '#f2f2f2', fontSize: '50px'}}>MIXED DIVISION</h1>
        <p style={{ fontSize: '14px', color: '#7B7B7B', marginBottom: '20px' }}>
          Roundnet Philippines - Individual Power Rankings
        </p>
      </div>
      <div className="data-search">
        <input type="text" placeholder='Search...' onChange={handleFilter} />
        <FaSearch className="magnifying-glass-icon" />
      </div>
      <DataTable 
        columns={columns} 
        data={filteredRecords}
        customStyles={customStyles} 
        className='data-cells'
        theme="solarized"
        pagination
        progressPending={pending}
      />
    </div>
  );
}

export default DivisionRanking3;
