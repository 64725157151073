import React, { useEffect } from 'react';

function RegisterEvent() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="register-container">
      <div className="register-event">
        <h2>Register Your Next Event</h2>
        <p>Don't miss out, register now for our upcoming event!</p>
        <p>Please read the <a href="https://docs.google.com/document/d/1jSclvxfRcGt5wrx57Z7pEjAfZXvZ1mORXxmLX2sNTYo/edit#heading=h.oeolqo7zla6n" target='_blank' rel='noreferrer'>RP Sanctioned Events and Power Ranking Guidelines</a> before filling-out this form.</p>
      </div>
      <div
        data-tf-widget="HHbPOt9f"
        data-tf-opacity="0"
        data-tf-iframe-props="title=Application Form for RP Sanctioned Event"
        data-tf-transitive-search-params
        data-tf-medium="snippet"
        style={{ width: '100%', height: '500px' }}
      ></div>
    </div>
  );
}

export default RegisterEvent;
