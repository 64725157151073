import React from 'react';
import picture from '../assets/gallery.png';
import { Link } from 'react-router-dom';

function GalleryPage() {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  return (
    <div className="gallery-first">
      <img
        alt="image5"
        src={picture}
        className="gallery-image05 image-notes"
      />
      <div data-aos="fade-up-right" className="gallery-content">
        <h2 className="gallery-header">
          Gallery
        </h2>
        <div className="gallery-list">
          <div className="gallery-note">
            <p className="gallery-text16">
              Capturing the Vibrant Moments of Roundnet Philippines
            </p>
          </div>
        </div>
        <Link to="/gallery" className="learn-more-link" onClick={handleScrollToTop}>
          View
        </Link>
      </div>
    </div>
  );
}

export default GalleryPage;