import React, { useEffect } from 'react';

const Calendar = () => {
  const [currentDate, setCurrentDate] = React.useState(new Date());

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        goToPreviousMonth();
      } else if (event.key === 'ArrowRight') {
        goToNextMonth();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const events = [
    {
      title: 'Philippines ARF Tour Stop',
      startDate: new Date(2023, 3, 28),
      endDate: new Date(2023, 3, 30)
    },
    {
      title: 'Japan ARF Tour Stop',
      startDate: new Date(2023, 4, 13),
      endDate: new Date(2023, 4, 14)
    },
    {
      title: 'Taiwan ARF Tour Stop',
      startDate: new Date(2023, 5, 24),
      endDate: new Date(2023, 5, 25)
    },
    {
      title: 'Indonesia ARF Tour Stop',
      startDate: new Date(2023, 6, 29),
      endDate: new Date(2023, 6, 30)
    },
    {
      title: 'ARF Cup',
      startDate: new Date(2023, 8, 21),
      endDate: new Date(2023, 8, 24)
    }
  ];

  const renderDaysOfWeek = () => {
    return daysOfWeek.map((day) => (
      <div key={day} className="calendar__day-of-week">
        {day}
      </div>
    ));
  };

  const renderCalendarDays = () => {
    const today = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const calendarDays = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      const prevMonth = new Date(currentYear, currentMonth, 0);
      const day = prevMonth.getDate() - i;
      calendarDays.push(
        <div key={`blank-${i}`} className="calendar__day prev-day">
          {day}
        </div>
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const isToday =
        day === today.getDate() &&
        currentMonth === today.getMonth() &&
        currentYear === today.getFullYear();
      const calendarDayClass = isToday ? 'calendar__day calendar__day--today' : 'calendar__day';

      const matchingEvents = events.filter((event) => {
        const eventStartDate = event.startDate.getDate();
        const eventEndDate = event.endDate.getDate();
        const eventMonth = event.startDate.getMonth();
        return day >= eventStartDate && day <= eventEndDate && currentMonth === eventMonth;
      });

      const eventBar =
        matchingEvents.length > 0 ? (
          <div className="calendar__event-bar" key={`event-bar-${day}`}>
            {matchingEvents[0].title}
          </div>
        ) : null;

      calendarDays.push(
        <div key={`day-${day}`} className={calendarDayClass}>
          {day}
          {eventBar}
        </div>
      );
    }

    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDay();
    for (let i = 1; i <= 6 - lastDayOfMonth; i++) {
      const nextMonth = new Date(currentYear, currentMonth + 1, i);
      const day = nextMonth.getDate();
      calendarDays.push(
        <div key={`next-${i}`} className="calendar__day next-day">
          {day}
        </div>
      );
    }

    return calendarDays;
  };

  const goToPreviousMonth = () => {
    setCurrentDate((prevDate) => {
      const prevMonth = prevDate.getMonth() - 1;
      const prevYear = prevDate.getFullYear();
      return new Date(prevYear, prevMonth, 1);
    });
  };

  const goToNextMonth = () => {
    setCurrentDate((prevDate) => {
      const nextMonth = prevDate.getMonth() + 1;
      const nextYear = prevDate.getFullYear();
      return new Date(nextYear, nextMonth, 1);
    });
  };

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  return (
    <div className="calendar">
      <div className="calendar-container">
        <div className="calendar__header">
          <h1 className="calendar__month-year">
            <div></div>
            <div>
              <button className="calendar__nav-button" onClick={goToPreviousMonth}>
                &lt;
              </button>
              {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
              <button className="calendar__nav-button" onClick={goToNextMonth}>
                &gt;
              </button>
            </div>
            <div>
              <button className="calendar__today-button" onClick={goToToday}>
                Today
              </button>
            </div>
          </h1>
        </div>
        <div className="calendar__weekdays">{renderDaysOfWeek()}</div>
        <div className="calendar__days">{renderCalendarDays()}</div>
      </div>
    </div>
  );
};

export default Calendar;
