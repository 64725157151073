import React from 'react';
import logo1 from '../assets/irfLogo.png';
import logo2 from '../assets/arfLogo.png';
import logo3 from '../assets/partnerLogo/archonLogo.png';
import logo4 from '../assets/partnerLogo/bigLogo.png';
import logo5 from '../assets/partnerLogo/jatriaLogo.png';
import logo6 from '../assets/partnerLogo/mbCoveLogo.png';
import logo7 from '../assets/partnerLogo/moviLogo.png';
import logo8 from '../assets/partnerLogo/papLogo.png';
import logo9 from '../assets/partnerLogo/rodicLogo.png';
import logo10 from '../assets/partnerLogo/rspsLogo.png';
import logo11 from '../assets/partnerLogo/rumbleLogo_white.png';
import logo12 from '../assets/partnerLogo/spikeLogo.png';
import logo13 from '../assets/partnerLogo/wtnLogo.png';

function Partners() {
  return (
    <>
    <div className="partners">
      <div className="header">
        <h1>Roundnet Philippines is a proud member of</h1>
      </div>
      <div className="image-container">
        <img src={logo1} alt="IRF" />
        <img src={logo2} alt="ARF" />
      </div>
    </div>
    <div className="partners2">
      <div className="header">
        <h1>Partners and Collaborators</h1>
        <h2>Companies and individuals we've worked so far.</h2>
      </div>
      <div className="image-container">
        <img src={logo11} alt="rumble" />
        <img src={logo3} alt="archon" />
        <img src={logo10} alt="rsps" />
        <img src={logo4} alt="big" className='small'/>
        <img src={logo5} alt="jatrial" className='small'/>
        <img src={logo6} alt="mbCove" className='small'/>
        <img src={logo7} alt="MoVi" className='small'/>
        <img src={logo8} alt="pap" className='small'/>
        <img src={logo9} alt="rodic" className='small'/>
        <img src={logo12} alt="spike" className='small'/>
        <img src={logo13} alt="wtn" className='small'/>
      </div>
    </div>
    </>
  );
}

export default Partners;
