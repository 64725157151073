import React from 'react';
import logo1 from '../assets/clubLogo/clublogo.png';
import logo2 from '../assets/clubLogo/bayawanLogo.png';
import logo3 from '../assets/clubLogo/frcLogo.png';
import logo4 from '../assets/clubLogo/valleyLogo.png';
import logo5 from '../assets/clubLogo/dsLogo.png';
import logo6 from '../assets/clubLogo/zagubangLogo.png';
import logo7 from '../assets/clubLogo/clublogo.png';
import logo8 from '../assets/clubLogo/crawlersLogo.png';
import logo9 from '../assets/clubLogo/clublogo.png';
import logo10 from '../assets/clubLogo/clublogo.png';
import logo11 from '../assets/clubLogo/clublogo.png';
import logo12 from '../assets/clubLogo/vanguardsLogo.png';
import logo13 from '../assets/clubLogo/squidLogo.png';
import logo14 from '../assets/clubLogo/nookiesLogo.png';

import groupPhoto1 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto2 from '../assets/groupPhoto/bayawanPhoto.png';
import groupPhoto3 from '../assets/groupPhoto/frcPhoto.png';
import groupPhoto4 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto5 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto6 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto7 from '../assets/groupPhoto/trafficPhoto.png';
import groupPhoto8 from '../assets/groupPhoto/crawlersPhoto.png';
import groupPhoto9 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto10 from '../assets/groupPhoto/katipunerosPhoto.png';
import groupPhoto11 from '../assets/groupPhoto/meatballsPhoto.png';
import groupPhoto12 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto13 from '../assets/groupPhoto/squidPhoto.png';
import groupPhoto14 from '../assets/groupPhoto/groupphoto.png';

const clubData = [
  {
    title: 'Bayawan Roundnet Community',
    subtitle: 'January 14, 2023',
    logo: logo2,
    website: 'https://www.facebook.com/Bayawanspikeballcommunity?mibextid=ZbWKwL',
    photo: groupPhoto2,
    description: '123 Main Street, Quezon City, Metro Manila, Philippines',
  },
  {
    title: 'Fairview Roundnet Club (FRC)',
    subtitle: 'January 14, 2023',
    logo: logo3,
    website: 'https://www.facebook.com/fairview.roundnet?mibextid=LQQJ4d',
    photo: groupPhoto3,
    description: '123 ABC Street, Quezon City, Metro Manila, Philippines',
  },
  {
    title: 'Valley Spikes Roundnet',
    subtitle: 'April 24, 2023',
    logo: logo4,
    website: 'https://www.facebook.com/ValleySpikesPH?mibextid=LQQJ4d',
    photo: groupPhoto4,
    description: '123 Palm Street, Makati City, Metro Manila, Philippines',
  },
  {
    title: 'Diliman Sunsetters ',
    subtitle: 'April 24, 2023',
    logo: logo5,
    website: 'https://www.facebook.com/DilimanSunsetters',
    photo: groupPhoto5,
    description: '123 Palm Street, Makati City, Metro Manila, Philippines',
  },
  {
    title: 'Zagubang Roundnet',
    subtitle: 'May 16, 2023',
    logo: logo6,
    website: 'https://www.facebook.com/zagubangcommunity',
    photo: groupPhoto6,
    description: '123 ABC Street, Quezon City, Metro Manila, Philippines',
  },
  {
    title: 'Traffic Lights Roundnet Club',
    subtitle: 'May 25, 2023',
    logo: logo7,
    website: 'https://www.facebook.com/profile.php?id=100092282843628&mibextid=LQQJ4d',
    photo: groupPhoto7,
    description: '123 Palm Street, Makati City, Metro Manila, Philippines',
  },
  {
    title: 'Night Crawlers',
    subtitle: 'May 26, 2023',
    logo: logo8,
    website: 'https://www.facebook.com/profile.php?id=100089670074498&mibextid=LQQJ4d',
    photo: groupPhoto8,
    description: '123 Palm Street, Makati City, Metro Manila, Philippines',
  },
  {
    title: 'Lethal Spikes',
    subtitle: 'June 01, 2023',
    logo: logo9,
    website: 'https://www.instagram.com/lethalspikes.6200/',
    photo: groupPhoto9,
    description: '123 Main Street, Quezon City, Metro Manila, Philippines',
  },
  {
    title: 'KATIPUNEROS',
    subtitle: 'June 01, 2023',
    logo: logo10,
    website: 'https://www.phdummyexample123.com',
    photo: groupPhoto10,
    description: '123 ABC Street, Quezon City, Metro Manila, Philippines',
  },
  {
    title: 'Vanguards',
    subtitle: 'June 09, 2023',
    logo: logo12,
    website: 'https://www.instagram.com/vanguards2023/',
    photo: groupPhoto12,
    description: '123 Palm Street, Makati City, Metro Manila, Philippines',
  },
];

const Clubs = () => {
  return (
    <>
      <div className="header-club">
        <div className="title-about">Member Clubs</div>
        <div className="subtitle">Connecting Roundnet enthusiasts across the Philippines through member clubs.</div>
      </div>
      {clubData.map((club, index) => (
        <div className="container-club" key={index}>
          <h1 className="title-club">{club.title}</h1>
          <h2 className="subtitle-club">{club.subtitle}</h2>
          <div className="content-club">
            <div className="left-image-club">
              <img src={club.logo} alt="Left Image" />
              <a href={club.website} target="_blank" className="description-club">
                {club.website}
              </a>
            </div>
            <div className="right-image-club">
              <img src={club.photo} alt="Right Image" />
              <p className="description-club">{club.description}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Clubs;
