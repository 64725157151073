import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaTwitch } from 'react-icons/fa';
import logo from '../assets/fulllogo_white.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-logo">
          <img src={logo} alt="Roundnet Philippines Logo" />
        </div>
        <div className="footer-content">
          <div className="footer-links">
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/learn">Learn</a>
            <a href="/events">Events</a>
            <a href="/membership">Membership</a>
            <a href="/ranking">Rankings</a>
          </div>
        </div>
        <div className="footer-bottom">
        <div className="footer-social">
          <a href="https://www.facebook.com/RoundnetPhilippines" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://twitter.com/RoundnetPHIL" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com/Roundnetphilippines/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.youtube.com/channel/UCqsYgUWWTXnV-MB7_EKC0pA" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
          <a href="https://www.twitch.tv/roundnetphilippines" target="_blank" rel="noopener noreferrer">
            <FaTwitch />
          </a>
        </div>
          <div className="all-rights">
          <p>All rights reserved &copy; 2023 Roundnet Philippines</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
