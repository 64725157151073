import React from 'react';
import tableImage from '../assets/table.png';

const PowerMatrix = () => {
  return (
    <>
      <div className="power-matrix-container">
        <h1 className="table-title">Power Ranking Matrix</h1>
        <img src={tableImage} alt="Power Ranking Matrix Table" />
        <p className="table-paragraph">x1.5 Multiplier for RP Nationals | x1.75 Multiplier for ARF Sanctioned Event</p>
        <p className="additional-paragraph">
          • The total points of a player eligible for the ranking is the sum of the points of the sanctioned tournaments he/she has played.
        </p>
        <p className="additional-paragraph">
          • There is a maximum number of tournament results that can account for the total score of a player. The maximum is set to 60% of the total ARF Sanctioned tournaments. (e.g. if in total 10 sanctioned tournaments are organized in the season, only  a maximum of 6 tournament results account for the total score. The 6 highest tournament scores of that player are taken into account.   
        </p>
        <p className="additional-paragraph">
          • This number will be rounded off to the nearest whole number in case of a decimal number (e.g. If there are a total of 7 sanctioned tournaments, 4 tournaments will account for the total score. That is: 60% of 7 is 4,2. This number will be rounded down to 4.)
        </p>
        <p className="additional-paragraph">
          • The ranking system resets every year/ season. All points will return to zero.
        </p>
      </div>
      <div className="multiplier-container">
        <div className="multiplier">
          <h1 className="table-title2">Multiplier Matrix</h1>
          <p className="additional-paragraph2">
            • A tournament will get a x1.5 multiplier increase if it is the RP Nationals, and a x1.75 multiplier increase if it is an ARF Sanctioned Event. The reasoning behind this is to encourage RP Clubs to join and host Major Tournaments in the Philippines.
          </p>
          <h1 className="table-title2">International Teams (Non-RP)</h1>
          <p className="additional-paragraph2">
            • International teams that are non-RP are welcome to compete in any sanctioned event (except for the RP Nationals). Since they don’t have any initial seeding, they will receive a random seed for forming groups. International teams (non-RP) will be spread out across groups as much as possible (subject to the tournament director’s discretion)
          </p>
          <p className="additional-paragraph2">
            • International teams don’t receive any RP Power Ranking points, so they are removed from the final rankings. Subsequently, all RP teams will shift up. This means if the final result is something like 1. Team A (RP) – 2. Team B (non-RP) – 3. Team C (RP), Team A will receive the points for first place, and Team C will receive the points for the second place. The size of the tournament that is used in the score matrix remains the same (with Non-RP teams included).
          </p>
        </div>
      </div>
    </>
  );
};

export default PowerMatrix;
