import React, { useEffect } from 'react';

function RegisterWorkshop() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="register-club">
      <form className="form-club">
        <div className="club-header">
          <h2>Roundnet Experience Workshop</h2>
          <p>Roundnet Philippines provides the opportunity to introduce this new sport called "Roundnet" to schools and organizations across the country. During the workshop:</p>
          <p> 
            Roundnet equipment will be provided <br/>
            Brief discussion and demo on how to play <br/>
            All participants will play and experience roundnet <br/>
          </p>
          <p>
          Optional: <br/>
            Mini-Tournament <br/>
            Modified drills and activities for specific age group and skill level <br/>
            Sharing of teaching resources (curriculum, videos, lesson plan for Teachers) <br/>
          </p>
        </div>
      </form>
      <div
        data-tf-widget="a2AgYB7O"
        data-tf-opacity="0"
        data-tf-iframe-props="title=Club Membership Application"
        data-tf-transitive-search-params
        data-tf-medium="snippet"
        style={{ width: '100%', height: '500px' }}
      ></div>
    </div>
  );
}

export default RegisterWorkshop;
