import "../scss/index.scss";
import { Footer, ScrollToTop } from "../components";
import HeaderGallery from "../components/headers/HeaderGallery";
import PhotoGallery from "../components/PhotoGallery";
import Transition from "../Transition";

function Gallery() {

  return (
    <div className="App">
      <ScrollToTop />
      <HeaderGallery />
      <PhotoGallery />
      <Footer />
    </div>
  );
}

export default Transition(Gallery);
