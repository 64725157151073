import "../scss/index.scss";
import { Footer, ScrollToTop } from "../components";
import HeaderEvents from '../components/headers/HeaderEvents';
import Calendar from "../components/Calendar";
import RegisterEvent from "../components/RegisterEvent";
import EventsList from "../components/EventsList";
import Sanction from "../components/Sanction";
import Transition from "../Transition";

function Events() {

  return (
    <div className="App">
      <ScrollToTop />
      <HeaderEvents />
      <EventsList />
      <Sanction />
      <Calendar />
      <RegisterEvent />
      <Footer />
    </div>
  );
}

export default Transition(Events);
