import React from 'react'
import picture from '../assets/why.png';

function PowerRankings() {
  return (
    <div className="rankings-roundnet">
    <div className="rankings">
    <div className="rankings-header">
      <div className="title-rankings">RP - Power Ranking System</div>
    </div>
    <div className="content-rankings">
      <div className="img">
          <img src={picture} alt='image' />
      </div>
      <div className="rankings-description">
      <p>
      ⸺ RP Power Ranking is an individual ranking system.</p>
      <p>
      ⸺ Every player that is an official member of Roundnet Philippines can earn points at a sanctioned event. </p>

      <p>  
      ⸺ This ranking is a standardized way to objectively rank the skill level of Filipino roundnet players.
       </p>
      
      <p>  
      ⸺ The amount of points a player gets in each tournament depends on two things:
      </p>
          <p className='rankings-paragraph'> ○ The result/ rank of the team at the end of the tournament   </p>
          <p className='rankings-paragraph'> ○ The size of the tournament (number of teams participating) </p>
      </div>
    </div>
  </div>
  </div>
  )
}

export default PowerRankings