import React from 'react';
import picture1 from '../assets/2v2.png';
import picture2 from '../assets/3touch.png';
import picture3 from '../assets/360ply.png';
import picture4 from '../assets/scoring.png';
import picture5 from '../assets/why.png';
import picture6 from '../assets/what.png';

function AboutRoundnet() {
  return (
    <div className="about-roundnet">
    <div className="about-net">
      <div className="header">
        <div className="title-about">Roundnet Basics</div>
        <div className="subtitle">The Basics of Roundnet!</div>
      </div>
      <div className="content-about">
        <div className="video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/P1joelbLD74"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            ></iframe>
        </div>
        <div className="description">
        <p>
        ⸺ What makes roundnet unique is that after the serve, there are no sides or boundaries. You can run, pass, and spike the ball off the net in any direction to try to win the point.</p>

        <p>  
        ⸺ When a team fails to return the ball, the rally is over, and the other team receives one point. Games are played up to 21 points. </p>
        </div>
      </div>
    </div>

    <div className="details-net">
      <div className="header">
        <div className="title-about">WHAT IS ROUNDNET?</div>
        <div className="subtitle">Unleash the Spike-Filled Action</div>
      </div>
      <div className="content-about">
        <div className="img">
            <img src={picture5} alt='whatisroundnet' />
        </div>
        <div className="description">
        <p>
        ⸺ The sport of roundnet is played 2-on-2 with each team starting on opposite sides of the net.
            Once the ball is served, you get up to three touches to try to return the ball back on the net just like 2-on-2 volleyball (bump, set, and spike). </p>
        <p>
        ⸺ What makes Roundnet unique is that after serving, there are no boundaries, You can run, pass and spike the ball off the net in any direction to try to win the point. When a team fails to return the ball, the rally is over and the other team receives one point. </p>

        <p>  
        ⸺ Games are generally up to 21 points but feel free to play whatever number you like. </p>
        </div>
      </div>
    </div>
    <div className="details-net">
      <div className="header">
        <div className="title-about">WHY PLAY ROUNDNET?</div>
        <div className="subtitle">Experience the Ultimate Fusion of Strategy, Agility, and Unforgettable Spike Battles.</div>
      </div>
      <div className="content-about">
        
        <div className="description">
        <p>
        ⸺ Roundnet is not just any game. It’s people coming together that gives a unique sense of community as it is so easy to play by anyone of all ages and abilities.</p>
        <p>
        ⸺ You can just easily pack the equipment and carry the gear around with ease. </p>

        <p>  
        ⸺ Roundnet can be played almost anywhere, from an open field, on the beach, or even inside a room. </p>
        </div>
        <div className="img">
            <img src={picture6} alt='whyplayroundnet' />
        </div>
      </div>
    </div>
    
      <section className='gameplay-section'>
      <div className="header">
        <div className="title-gameplay">Basic Gameplay</div>
        <div className="subtitle">Mastering the Spike: Roundnet Thrills in the Philippines</div>
      </div>
      <div className="container-card">
        <div className="cards">
            <div className="face face1">
                <div className="content">
                    <img src={picture1} alt="" />
                    <h3>2 VS 2 PLAY</h3>
                </div>
            </div>
            <div className="face face2">
                <div className="content">
                    <p>Team A hits the ball down to the net towards team B.</p>
                </div>
            </div>
        </div>
        <div className="cards">
            <div className="face face1">
                <div className="content">
                    <img src={picture2} alt="" />
                    <h3>3 TOUCHES</h3>
                </div>
            </div>
            <div className="face face2">
                <div className="content">
                    <p>Teams have 3 alternating touches (receive, set, spike) to return the ball to the net.</p>
                </div>
            </div>
        </div>
        <div className="cards">
            <div className="face face1">
                <div className="content">
                    <img src={picture3} alt="" />
                    <h3>360 PLAY</h3>
                </div>
            </div>
            <div className="face face2">
                <div className="content">
                    <p>After the serve, players can move or hit any direction.</p>
                </div>
            </div>
        </div>
        <div className="cards">
            <div className="face face1">
                <div className="content">
                    <img src={picture4} alt="" />
                    <h3>SCORING</h3>
                </div>
            </div>
            <div className="face face2">
                <div className="content">
                    <p>A score is gained if a team can't return the ball; or it touches the ground, rim, or bounces twice. First to 11, 15, or 21 wins!</p>
                </div>
            </div>
        </div>  
      </div>
      </section>
    </div>
  );
}

export default AboutRoundnet;
