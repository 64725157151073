import React, { Component } from "react";
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import picture1 from '../assets/1.png';
import picture2 from '../assets/2.png';
import picture3 from '../assets/3.png';
import picture4 from '../assets/4.png';
import picture5 from '../assets/5.png';
import picture6 from '../assets/6.png';
import picture7 from '../assets/7.png';
import picture8 from '../assets/8.png';
import picture9 from '../assets/9.png';
import picture10 from '../assets/10.png';
import picture11 from '../assets/11.png';

const slidesData = [
  {
    picture: picture1,
    date: "September 8-11, 2022",
    title: "First Roundnet World Championships",
    link: "https://twitter.com/RoundnetFed/status/1459204924592599051?s=20",
  },
  {
    picture: picture2,
    date: "Nov 26, 2021",
    title: "Sambali Spike Camp",
    link: "https://twitter.com/RoundnetPHIL/status/1464143774980972544?s=20",
  },
  {
    picture: picture3,
    date: "Nov 23, 2021",
    title: "First-Ever Spike Camp",
    link: "https://twitter.com/RoundnetPHIL/status/1463056617474523137?s=20",
  },
  {
    picture: picture4,
    date: "June 2, 2022",
    title: "PH team invited to 2022 Roundnet World Championships",
    link: "https://www.ptvnews.ph/ph-team-invited-to-2022-roundnet-world-championships/",
  },
  {
    picture: picture5,
    date: "March 21, 2020",
    title: "Kikiam Cup Roundnet Fun Play",
    link: "https://www.facebook.com/watch/?v=2551627531770444",
  },
  {
    picture: picture6,
    date: "July 7, 2022",
    title: "PTV Feature: Roundnet, pinapalakas sa Pilipinas",
    link: "https://www.youtube.com/watch?v=_fw9gXcyDhI",
  },
  {
    picture: picture7,
    date: "June 28, 2022",
    title: "Roundnet featured in an episode of AHA! GMA 7 | Aha-mazing ball games!",
    link: "https://www.youtube.com/watch?v=6EVK6227BaM",
  },
  {
    picture: picture8,
    date: "June 22, 2022",
    title: "NU Lady Bulldogs winning championships",
    link: "https://www.facebook.com/watch/?v=822500715380395",
  },
  {
    picture: picture9,
    date: "June 22, 2022",
    title: "NU Lady Bulldogs winning championships",
    link: "https://www.facebook.com/watch/?v=822500715380395",
  },
  // ... (other slide data)
];

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.state = {
      activeSlide: 0,
      previousActiveSlide: null,
      autoClickCounter: 0,
    };
  }

  componentDidMount() {
    this.startAutoPlay();
  }

  componentWillUnmount() {
    this.stopAutoPlay();
  }

  startAutoPlay = () => {
    this.interval = setInterval(this.autoPlay, 2000); // Change slide every 2 seconds
  };

  stopAutoPlay = () => {
    clearInterval(this.interval);
  };

  autoPlay = () => {
    const { activeSlide, autoClickCounter } = this.state;
    const nextSlide = (activeSlide + 1) % slidesData.length;

    this.setState({ activeSlide: nextSlide }, () => {
      if ((nextSlide + 1) % 3 === 0) {
        setTimeout(() => {
          this.slider.current.slickNext(); // Move to the next set of slides after 2 seconds
          this.setState({ autoClickCounter: autoClickCounter + 1 });
        }, 2000);
      }
    });
  };

  handleSlideHover = (index) => {
    this.stopAutoPlay(); // Pause automatic sliding
    this.setState((prevState) => ({
      previousActiveSlide: prevState.activeSlide, // Store the previous active slide
      activeSlide: index, // Highlight the hovered slide
    }));
  };

  handleSlideLeave = () => {
    this.startAutoPlay(); // Resume automatic sliding
    this.setState((prevState) => ({
      activeSlide: prevState.previousActiveSlide, // Revert to the previous active slide after unhovering
    }));
  };

  render() {
    const settings = {
      dots: false, // No need for dots when active border moves
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        // ... (responsive settings)
      ],
    };

    return (
      <div
        className="carousel-container"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Slider ref={this.slider} {...settings}>
          {slidesData.map((slide, index) => (
            <div
              className={`carousel-slide ${index === this.state.activeSlide ? 'active-slide' : ''}`}
              key={index}
              onMouseEnter={() => this.handleSlideHover(index)} // Handle slide hover
              onMouseLeave={this.handleSlideLeave} // Handle slide leave
            >
              <a href={slide.link} target="_blank" rel="noreferrer">
                <div className="image-container">
                  <LazyLoadImage
                    src={slide.picture}
                    alt={`image${index + 1}`}
                    effect="blur"
                    className="carousel-image"
                  />
                  <div className="image-date">{slide.date}</div>
                  <span className="image-title">{slide.title}</span>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}