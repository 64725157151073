import React from 'react'

function DownloadRules() {
  return (
    <>
    <div className="download-section laws">
      <div className="title-rules">
        <h2>Download Roundnet Rules</h2>
        <p>International Roundnet Federation Rules</p>
      </div>
      <div className="button-container">
        <a href='https://www.roundnetfederation.org/s/IRF-Rules-Final-Updated-8102022-date-fix.pdf' className='button-download'>
          Download
        </a>
      </div>
    </div>
    <div className="download-section guidelines">
      <div className="title-rules">
        <h2>Observer Guidelines</h2>
        <p>Here you will find the observer guidelines adapted by the committee</p>
      </div>
      <div className="button-container">
        <a href='https://www.roundnetfederation.org/observer-guidelines' target='blank' className='button-download'>
          View More
        </a>
      </div>
    </div>
    </>
  )
}

export default DownloadRules