import React, { useRef, useState } from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const form = useRef();
  const [showSentMessage, setShowSentMessage] = useState(false);
  const [verified, setVerified] = useState(false);

  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7lmfzvr', 'template_ymas8o5', form.current, 'TgAmvbduG0dw2n64i')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        setShowSentMessage(true);
        setTimeout(() => {
          setShowSentMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <div className='contact-container'>
      <div className="container-contact">
        <div className="content-contact">
          <div className="left-side">
            <div className="address details">
              <FaMapMarkerAlt className="icon" style={{ fill: 'black', fontSize: "40px" }} />
              <div className="topic">Address</div>
              <div className="text-one">Batasan Hills</div>
              <div className="text-two">Quezon City</div>
            </div>
            <div className="phone details">
              <FaPhoneAlt className="icon" style={{ fill: 'black', fontSize: "40px" }} />
              <div className="topic">Phone</div>
              <div className="text-one">+63918-488-7041</div>
              <div className="text-two">+0096 3434 5678</div>
            </div>
            <div className="email details">
              <FaEnvelope className="icon" style={{ fill: 'black', fontSize: "40px" }} />
              <div className="topic">Email</div>
              <div className="text-one">events@roundnetph.com</div>
              <div className="text-two">info@roundnetph.com</div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">Send us a message</div>
            <p>
              If you have any inquiries or need assistance regarding Roundnet in the Philippines, feel free to drop us a message. We're here to support you!
            </p>
            {showSentMessage ? (
              <div className="sent-message">Sent!</div>
            ) : (
              // Show ReCAPTCHA instead of the form
              <form ref={form} onSubmit={sendEmail}>
                <div className="input-box">
                  <input type="text" placeholder="Enter your name" name="user_name" required/>
                </div>
                <div className="input-box">
                  <input type="text" placeholder="Enter your email" name="user_email" required/>
                </div>
                <div className="input-box message-box">
                  <input type="text" placeholder="Enter your message" name="message" required/>
                </div>
                <div className="button">
                  <input
                    type="submit"
                    value="Send"
                    disabled={!verified}
                  />
                </div>
                <ReCAPTCHA
                  sitekey="6Lf1OGYnAAAAAD7QI-kvza3qaI_Am6cwcv05WJaq"
                  onChange={onChange}
                />
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
