import React, { useEffect, useState } from 'react';

const LoadingPage = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    document.body.classList.add('loading');
    loadData();
    return () => {
      document.body.classList.remove('loading');
    };
  }, []);

  const loadData = async () => {
    // Simulate loading data
    const data = await fetchData(); // Replace with your actual data loading function

    // Simulate progress
    let currentProgress = 0;
    const progressInterval = setInterval(() => {
      currentProgress += 1;
      if (currentProgress >= 100) {
        clearInterval(progressInterval);
      }
      setProgress(currentProgress);
    }, data.length); // Adjust the timing based on your actual loading time

    // Process the loaded data
    // ...

    // Complete loading
    setTimeout(() => {
      setProgress(100);
    }, data.length); // Adjust the timing based on your actual loading time
  };

  const fetchData = () => {
    // Replace this with your actual data loading logic
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = []; // Replace with your loaded data
        resolve(data);
      }, 2000); // Simulate a 2-second loading time
    });
  };

  return (
    <div className="loading-page">
      <div className="wave-container">
        <div className="wave">
          <div className="loading-ball" style={{ animationDelay: '0s' }} />
        </div>
        <div className="wave">
          <div className="loading-ball" style={{ animationDelay: '0.2s' }} />
        </div>
        <div className="wave">
          <div className="loading-ball" style={{ animationDelay: '0.4s' }} />
        </div>
      </div>
      <div className="loading-bar-container">
        <div className="loading-bar" style={{ width: `${progress}%` }} />
        <div className="loading-text">{Math.round(progress)}%</div>
      </div>
    </div>
  );
};

export default LoadingPage;
