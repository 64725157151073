  import React, { useState } from "react";
  import { GiHamburgerMenu } from "react-icons/gi";
  import { MdClose } from "react-icons/md";
  import logo from "../assets/fulllogo_white.png";
  import { Link } from 'react-router-dom';

  const Navbar = () => {
    const [navState, setNavState] = useState(false);
    return (
      <nav>
        <div className="brand-container">
          <div className="brand">
          <Link to="/" onClick={() => setNavState(false)}>
            <img src={logo} alt="logo" />
          </Link>
          </div>
          <div className="toggle-container">
            <div className="toggle">
              {navState ? (
                <MdClose onClick={() => setNavState(false)} />
              ) : (
                <GiHamburgerMenu onClick={() => setNavState(true)} />
              )}
            </div>
          </div>
        </div>
        <div className={`links-container ${navState ? "nav-visible" : ""}`}>
          <ul className="links">
            <li>
              <Link to="/" onClick={() => setNavState(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={() => setNavState(false)}>
                About
              </Link>
            </li>
            <li>
              <Link to="/learn" onClick={() => setNavState(false)}>
                Learn
              </Link>
            </li>
            <li>
              <Link to="/events" onClick={() => setNavState(false)}>
                Events
              </Link>
            </li>
            <li>
              <Link to="/membership" onClick={() => setNavState(false)}>
                Membership
              </Link>
            </li>
            <li>
              <Link to="/ranking" onClick={() => setNavState(false)}>
                Rankings
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  };

  export default Navbar;
