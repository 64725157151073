import React from 'react';
import picture1 from '../assets/1.png';
import picture2 from '../assets/6.png';
import picture3 from '../assets/3.png';
import picture4 from '../assets/4.png';
import picture5 from '../assets/7.png';
import { FaRegClock , FaMapMarkerAlt, } from 'react-icons/fa';

function EventsList() {
  const events = [
    {
      image: picture1,
      title: "Philippines ARF Tour Stop",
      location: "@Dumaguette, Philippines",
      date: "Apr 28 - Apr 30",
      address: "Ang Tay Golf Course, Dumaguete, Negros Oriental, Philippines",
      time: "12:00 AM — 12:00 AM (CST)",
      description:
        "A 2-day roundnet tournament on April 28-29, 2023, at Ang Tay Golf Course, Dumaguete City, featuring single gender and coed categories, with group and bracket stages.",
      link: "https://fwango.io/arftour-ph",
    },
    {
      image: picture2,
      title: "Japan ARF Tour Stop",
      location: "@Tokyo, Japan",
      date: "May 13 - May 14",
      address: "Tonegawa Yuyu Park, Nakabyo, Abiko, Chiba, Japan",
      time: "8:00 AM — 4:00 PM (JST)",
      description:
        "ARF Tour Japan is a roundnet tournament by Roundnet Japan and Asian Roundnet Federation, featuring men's, women's, and mixed divisions, held on May 13-14 with cash prizes, Rumble sets, and medals.",
        link: "https://fwango.io/asiantourjapan",
    },
    {
      image: picture3,
      title: "Taiwan ARF Tour Stop",
      location: "@Taipei, Taiwan",
      date: "Jun 24 - Jun 25",
      address: "Taiwan, Taoyuan City, Zhongli District, Yuan Ze University",
      time: "8:00 AM — 6:00 PM (CST)",
      description:
        "ARF Tour Taiwan offers single-gender and mixed tournaments on June 24-25 with a $75,000 NTD prize pool for ranked divisions, including check-in, group stage, bracket stage, and raffle prizes.",
      link: "https://fwango.io/arf-tour-taiwan",
    },
    {
      image: picture4,
      title: "Indonesia ARF Tour Stop",
      location: "@Jakarta, Indonesia",
      date: "Jul 29 - Jul 30",
      address: "Jakarta Sports Center, Jalan Gelora, Senayan",
      time: "9:00 AM — 6:00PM (GMT+7)",
      description:
        "Don't miss the Indonesia ARF Tour Stop in Jakarta, where top roundnet teams will compete for the championship in thrilling matches, showcasing their skills and intense competition. Join us for this exciting event!",
        link: "http://roundnetindonesia.com/",
    },
    {
      image: picture5,
      title: "ARF Cup",
      location: "@Bali, Indonesia",
      date: "Sep 21 - Sep 24",
      address: "Lapangan Niti Mandala Renon - Monumen Perjuangan Rakyat Bali,",
      time: "7:30 AM — 6:00 PM (GMT+8)",
      description:
        "The ARF Cup at the Bali Roundnet Festival brings together top teams from multiple nations, competing fiercely for roundnet supremacy and the ultimate championship title.",
      link: "https://www.asianroundnetfederation.com/arf-cup",
    },
  ];

  return (
    <div className="events">
      <div className="header">
        <div className="title">Full Event Schedule </div>
        <div className="subtitle">Explore the Complete Event Schedule</div>
      </div>
    <div className="event-container">
      {events.map((event, index) => (
          <div className="item-container" key={index}>
          <div
            className="img-container"
            style={{ backgroundImage: `url(${event.image})` }}
            ></div>

          <div className="body-container">
            <div className="overlay"></div>

            <div className="event-info">
              <p className="title-event">{event.title}</p>
              <div className="separator"></div>
              <p className="location">{event.location}</p>
              <p className="date">{event.date}</p>

              <div className="additional-info">
                <p className="info-description">
                    <span className="map-marker-icon">
                        <FaMapMarkerAlt />
                    </span>
                    {event.address}
                </p>
                <p className="info-description">
                    <span className="map-marker-icon">
                        <FaRegClock />
                    </span>
                  {event.time}
                </p>

                <p className="info-description">
                  {event.description} <span>more...</span>
                </p>
              </div>
            </div>
            <button className="action" onClick={() => window.open(event.link, "_blank")}> More Info</button>
          </div>
        </div>
      ))}
    </div>
</div>
  );
}

export default EventsList;
