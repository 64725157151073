import React from 'react';
import Navbar from '../Navbar';

const HeaderJoin = () => {
  return (
    <header className="headers header-join">
      <Navbar />
      <h1 className="title">Join Us</h1>
    </header>
  );
};

export default HeaderJoin;