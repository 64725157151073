import "../scss/index.scss";
import { Footer, ScrollToTop } from "../components";
import HeaderRanking from "../components/headers/HeaderRanking";
import PowerRankings from "../components/PowerRankings";
import PowerMatrix from "../components/PowerMatrix";
import Transition from "../Transition";
import DivisionRanking1 from "../components/DivisionRanking1";
import DivisionRanking2 from "../components/DivisionRanking2";
import DivisionRanking3 from "../components/DivisionRanking3";


function Rankings () {

  return (
    <div className="App">
      <ScrollToTop />
      <HeaderRanking />
      <PowerRankings />
      <PowerMatrix />
      <DivisionRanking1 />
      <DivisionRanking2 />
      <DivisionRanking3 />
      <Footer />
    </div>
  );
}

export default Transition(Rankings);
