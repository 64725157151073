import React, { useState } from 'react';
import picture1 from '../assets/values.png';
import picture2 from '../assets/mission.png';
import picture3 from '../assets/vision.png';
import picture4 from '../assets/12.png';

function Values() {
  const [clickedCardIndex, setClickedCardIndex] = useState(null);

  function handleCardClick(index) {
    setClickedCardIndex(index === clickedCardIndex ? null : index);
  }

  return (
    <>
      <div className="home-first">
        <div data-aos="fade-up-right" className="home-content">
          <h2 className="home-header">
            Welcome to Roundnet Philippines!
          </h2>
          <div className="home-list">
            <div className="home-note">
              <div className="home-point"></div>
              <p className="home-text16">
                At Roundnet Philippines, our primary mission is to promote the sport of Roundnet by forming a unified governing body that supports the growing community of this exciting sport here in the Philippines.
              </p>
            </div>
            <div className="home-note1">
              <div className="home-point01"></div>
              <p className="home-text17">
                We are passionate about providing a platform for players of all backgrounds and skill levels to come together, compete, and have a blast!
              </p>
            </div>
          </div>
        </div>
        <img
          alt="image5"
          src={picture4}
          className="home-image06"
        />
      </div>
      <div className="values">
          <div className="header-val">
            <div className="title-about">Defining Our Core Values</div>
            <div className="subtitle">Building a strong ethical foundation for success.</div>
          </div>
        <div className="container-values">
          <div className="cards">
            <div className={`card ${clickedCardIndex === 0 ? 'expanded' : ''}`} onClick={() => handleCardClick(0)}>
              <div className="imgBx">
                <img src={picture1} alt="values" />
              </div>
              <div className="content-values">
                <h2>Values</h2>
                <p>
                  <span>Passion for the sport</span> – to make sure everyone gets to have quality experience and maximum fun all in the name of good spirited games.
                </p>
                <p>
                  <span>Integrity</span> – to become the better version of ourselves and make sure we are all improving.
                </p>
                <p>
                  <span>Solidarity</span> – this is what holds our community together, we support one another, we grow as one and we succeed as one.
                </p>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className={`card ${clickedCardIndex === 1 ? 'expanded' : ''}`} onClick={() => handleCardClick(1)}>
              <div className="imgBx">
                <img src={picture2} alt="values" />
              </div>
              <div className="content-values">
                <h2>Mission</h2>
                <p>
                  <span>We Aim To:</span>
                </p>
                <ul>
                  <li>Promote the sport of the Roundnet throughout the Philippines</li>
                  <li>Develop players through regular workshops, pick-up games and organized tournaments.</li>
                  <li>Build a collaborative community that will influence others to engage in an active lifestyle for better health.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className={`card ${clickedCardIndex === 2 ? 'expanded' : ''}`} onClick={() => handleCardClick(2)}>
              <div className="imgBx">
                <img src={picture3} alt="values" />
              </div>
              <div className="content-values">
                <h2>Vision</h2>
                <p>Bring the sport of Roundnet to everyone regardless of race, gender, religion and capabilities around the nation.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Values;
