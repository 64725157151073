import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Navbar from "./Navbar";
import Carousel from "./Carousel";
import spike from "../assets/Spiking.png";
import { FaFacebook, FaYoutube, FaInstagram, FaTwitch, FaTwitter } from 'react-icons/fa';
import logo from "../assets/rumblelogo.png";
import video from "../assets/video-1.mp4";

const Home = () => {
  const [setNavState] = useState(false);

  return (
    <div className="home-container">
      <section className="home-hero">
        <header data-thq="thq-navbar" className="home-navbar">
          <Navbar />
        </header>
        <div className="home-social-icons">
          <a href="https://www.facebook.com/RoundnetPhilippines" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.youtube.com/channel/UCqsYgUWWTXnV-MB7_EKC0pA" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
          <a href="https://www.instagram.com/Roundnetphilippines/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.twitch.tv/roundnetphilippines" target="_blank" rel="noopener noreferrer">
            <FaTwitch />
          </a>
          <a href="https://twitter.com/RoundnetPHIL" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
        <div className="home-hero-content">
        <div class="hero-container">
          <h1 class="home-text07">
            <span>ROUNDNET</span>
            <br />
            <span>PHILIPPINES</span>
          </h1>
          <h1 class="home-text11">
              Unleash Your Power, Dominate the Roundnet!
          </h1>
          <ul class="home-button">
            <li>
              <Link to="/join">
                Join Us
              </Link>
            </li>
          </ul>
        </div>
          <div className="home-container2">
            <span className="home-text14">News and Feeds</span>
            <span className="home-text15">
              Stay up to date with the current news about Roundnet!
            </span>
            <Carousel />
          </div>
          <img alt="image4" src={spike} className="home-image04" />
        </div>
        <div className="home-background1">
          <video autoPlay muted loop>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
        <div className="sponsorship">
          <p>Proudly sponsored by:</p>
          <a href="https://www.rumbleroundnet.com/">
            <img src={logo} alt="sponsor logo" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default Home;
