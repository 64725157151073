import React, { useState, useEffect } from 'react';

const CircleCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    const handleMouseEnter = () => {
      setHovered(true);
    };

    const handleMouseLeave = () => {
      setHovered(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseenter', handleMouseEnter);
    window.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseenter', handleMouseEnter);
      window.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    const linkElements = document.getElementsByTagName('a');

    if (rootElement) {
      rootElement.style.cursor = 'transparent';
    }

    if (linkElements) {
      Array.from(linkElements).forEach((link) => {
        link.addEventListener('mouseenter', () => {
          setHovered(true);
        });

        link.addEventListener('mouseleave', () => {
          setHovered(false);
        });

        link.style.cursor = 'transparent';
      });
    }
  }, []);

  return (
    <div
      className={`circle-cursor${hovered ? ' hover' : ''}`}
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    ></div>
  );
};

export default CircleCursor;
