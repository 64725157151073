import "../scss/index.scss";
import { Footer, ScrollToTop } from "../components";
import HeaderAbout from "../components/headers/HeaderAbout";
import Values from "../components/Values";
import MeetTheTeam from "../components/MeetTheTeam";
import Timeline from "../components/Timeline";
import Partners from "../components/Partners";
import Reel from "../components/Reel";
import Transition from "../Transition";

function AboutUs() {

  return (
    <div className="App">
      <ScrollToTop />
      <HeaderAbout />
      <Values />
      <Reel />
      <MeetTheTeam />
      <Timeline />
      <Partners />
      <Footer />
    </div>
  );
}

export default Transition(AboutUs);
