import React, { useRef, useState } from 'react';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

const SubscribeNewsletter = () => {
  const [verified, setVerified] = useState(false);

  //recaptcha function
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }


  const form = useRef();
  const [showSentMessage, setShowSentMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_nq8982r', 'template_rmmwv71', form.current, 'TgAmvbduG0dw2n64i')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        setShowSentMessage(true);

        // Hide the "sent" message after 3 seconds
        setTimeout(() => {
          setShowSentMessage(false);
        }, 3000);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="subscribe-newsletter">
      <div className="subscribe-newsletter__content">
        <h2 className="subscribe-newsletter__title">Subscribe to our newsletter</h2>
        <p className="subscribe-newsletter__subtitle">
          Sign up for our newsletter to get the latest news!
        </p>
        {showSentMessage ? (
          <div className="subscribe-newsletter__sent-message">Sent!</div>
        ) : (
          <form ref={form} onSubmit={sendEmail} className="subscribe-newsletter__form">
              <ReCAPTCHA
                sitekey="6Lf1OGYnAAAAAD7QI-kvza3qaI_Am6cwcv05WJaq"
                onChange={onChange}
              />
              <br/>
            <div className="subscribe-newsletter__form-group">
              <input
                type="email"
                name="user_email"
                className="subscribe-newsletter__input"
                placeholder="Enter your email"
                required
              />
              <button type="submit" value="Send" className="subscribe-newsletter__button" disabled={!verified}>
                <HiOutlineArrowNarrowRight className="subscribe-newsletter__send-icon" />
              </button>
            </div>
          </form>
        )}
        <p className="subscribe-newsletter__disclaimer">
          By subscribing, you agree to receive promotional<br /> emails  from us. You can unsubscribe at any time.
        </p>
      </div>
    </div>
  );
};

export default SubscribeNewsletter;
