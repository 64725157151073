import React, { useEffect } from 'react';

function RegisterClub() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
    <div className="register-club">
      <form className="form-club">
        <div className="club-header">
          <h2>Member Clubs</h2>
          <p>Complete the form to apply for RP membership or update your Club directory information.</p>
          <p>Welcome to Roundnet Philippines, the national governing body for the sport of roundnet. We’re glad you are here and would like to invite you to join our growing community of roundnet clubs and associations. As your Federation, we are here to serve and support everyone without favor or bias. We respect and support your right to organize yourselves, develop your own organization, create and promote your own logo and brand and enjoy your own autonomy. <br/><br/>
 
          In short, we are here to help you win and have fun! <br/><br/>
  
          As a member, you are supporting RP and will be eligible to receive a number of exclusive benefits including free roundnet clinics, certifications,  and webinars. Access to sets, balls, and other supplies for sanctioned events and low cost sets for distribution to new players. Members will also enjoy promotional support on our website and social media platforms and qualify to enter in invitational tournaments and participate in select Team Philippine events. <br/><br/>
 
          Please complete the form below to apply for membership or update your club information
          </p>
        </div>
      </form>
      <div
        data-tf-widget="XRyqGTM0"
        data-tf-opacity="0"
        data-tf-iframe-props="title=Club Membership Application"
        data-tf-transitive-search-params
        data-tf-medium="snippet"
        style={{ width: '100%', height: '500px' }}
      ></div>
    </div>
    </>
  );
}

export default RegisterClub;
