import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';
import picture1 from '../assets/1.png';
import picture2 from '../assets/2.png';
import picture3 from '../assets/3.png';

const eventsData = [
  {
    id: 3,
    date: '24-25',
    month: 'Jun',
    title: 'Taiwan ARF Tour Stop',
    description: ' Taipei, Taiwan',
    websiteUrl: 'https://fwango.io/arf-tour-taiwan',
    backgroundImage: picture1,
  },
  {
    id: 4,
    date: '29-30',
    month: 'July',
    title: ' Indonesia ARF Tour Stop',
    description: 'Jakarta, Indonesia',
    websiteUrl: 'http://roundnetindonesia.com/',
    backgroundImage: picture2,
  },
  {
    id: 5,
    date: '21-24',
    month: 'Sep',
    title: 'Arf Cup',
    description: ' Bali, Indonesia',
    websiteUrl: 'https://www.asianroundnetfederation.com/arf-cup',
    backgroundImage: picture3,
  },
];

const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const Event = () => {
  useEffect(() => {
    const handleScroll = () => {
      const eventSection = document.querySelector('.event-section');
      const scrollPosition = window.scrollY;

      if (scrollPosition > 0) {
        eventSection.classList.add('scrolled');
      } else {
        eventSection.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="event-section">
      <div className="event-section__content">
        <h2 className="event-section__title">Upcoming Events</h2>
        <p className="event-section__paragraph">
          Something new, big and exciting! Don't miss out on these exciting events.
        </p>
        <div className="event-section__event-list">
          {eventsData.map((event) => (
            <div className="event-section__event" key={event.id}>
              <div className="event-section__event-date">
                <div
                  className="event-section__event-date-inner"
                  style={{ backgroundImage: `url(${event.backgroundImage})` }}
                >
                  <div className="event-section__event-number">{event.date}</div>
                  <div className="event-section__event-month">{event.month}</div>
                </div>
              </div>
              <div className="event-section__event-details">
                <h3 className="event-section__event-title">{event.title}</h3>
                <p className="event-section__event-description">
                  <span className="event-section__event-location-icon">
                    <FaMapMarkerAlt />
                  </span>
                  {event.description}
                </p>
                <a
                  href={event.websiteUrl}
                  className="event-section__event-see-more"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See More
                </a>
              </div>
            </div>
          ))}
        </div>
        <Link to="/events" className="event-section__see-all" onClick={handleScrollToTop}>
          See All
        </Link>
      </div>
    </section>
  );
};

export default Event;
