import React from 'react';
import img from '../assets/Buy.png';
import img2 from '../assets/Buy2.png';

function Buy() {
  return (
    <>
        <div className='buy-container'>
          <a href="https://shopee.ph/rumbleroundnetph" target="_blank" rel="noopener noreferrer">
          <img src={img} className='img-buy' alt="Buy Now" />
          </a>
          <a href="https://www.freepik.com/free-psd/black-friday-super-sale-facebook-cover-template_34013082.htm#page=3&query=banner%20shopee&position=5&from_view=search&track=ais" className='attribute'>Image by uixtube
          on Freepik
          </a> 
        </div>
        <div className='buy-container2'>
      <a href="https://shopee.ph/rumbleroundnetph" target="_blank" rel="noopener noreferrer">
        <img src={img2} className='img-buy' alt="Buy Now" />
      </a>
        <a href="https://www.freepik.com/free-psd/black-friday-super-sale-facebook-cover-template_34013082.htm#page=3&query=banner%20shopee&position=5&from_view=search&track=ais" className='attribute'>Image by uixtube
        on Freepik
        </a> 
    </div>
    </>
  );
}

export default Buy;
