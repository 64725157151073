import React from 'react';
import { Link } from 'react-router-dom';

const Teams = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scrolling smooth
    });
  };

  return (
    <div className="team-section">
      <div className="background-img"></div>
      <div className="content">
        <h2 className="title">Behind Roundnet</h2>
        <p className="description">Meet some people behind Roundnet Philippines</p>
        <Link to="/membership" className="link" onClick={scrollToTop}>
          Meet the team
        </Link>
      </div>
    </div>
  );
};

export default Teams;
