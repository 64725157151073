import React from 'react';
import Navbar from '../Navbar';

const HeaderGallery = () => {
  return (
    <header className="headers header-gallery">
      <Navbar />
      <h1 className="title">Gallery</h1>
    </header>
  );
};

export default HeaderGallery;