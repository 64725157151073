import "../scss/index.scss";
import {
  Footer,
  ScrollToTop,
} from "../components";
import HeaderJoin from "../components/headers/HeaderJoin";
import RegisterClub from "../components/RegisterClub";
import RegisterEvent from "../components/RegisterEvent";
import Transition from "../Transition";
import RegisterWorkshop from "../components/RegisterWorkshop";

function JoinUs() {

  return (
    <div className="App">
      <ScrollToTop />
      <HeaderJoin />
      <RegisterClub />
      <RegisterEvent />
      <RegisterWorkshop />
      <Footer />
    </div>
  );
}

export default Transition(JoinUs);
