import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import picture1 from '../assets/1.png';
import picture2 from '../assets/2.png';
import picture3 from '../assets/3.png';
import { Link } from 'react-router-dom';

function Sanction() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

    const content = [
      {
        title: 'What is sanction tournament?',
        paragraphs: [
          '• A Sanctioned tournament is an event conducted by an authorized official club / association under the guidelines and rules of Roundent Philippines (RP) and has received formal approval.',
          '• Sanctioned tournaments will provide players assurance of a consistent, high quality and safe tournament with the potential of earning Philippine and Asian ranking points.',
        ],
        image: picture1,  
      },
      {
        title: 'Why should your tournament be sanctioned',
        paragraphs: [
          '• Tournament status. It gives players the confidence that the tournament will be conducted within reasonable standards under the guidelines and rules of the RP.',
          '• Social media publications of events on RP’s platforms. Including the event calendar.',
          '• Rights to use RP logo and name on event materials',
          '• Players having the opportunity to earn RP Power ranking points',
          '• RP Equipment, financial, & staff support (if available)',
          '• More cooperation between RP players & organizers',
          '• Provide a checklist for member club/association to help manage the tournament',
          '• Group insurance (when available)'
        ],
        image: picture2,
      },
      {
        title: 'Requirements for a sanctioned tournament',
        paragraphs: [
          '• Tournament host should be an RP member.',
          <span>
            Not a member? <Link to="/joinus" target="_blank" rel="noopener noreferrer">Please apply here.</Link>
          </span>,
          '• A men’s tournament consisting of at least 16 teams in the tournament’s highest division.',
          '• A women’s tournament consisting of at least 8 teams in the tournament’s highest division.',
          '• Tournament has to be open to all.',
          '• Played in a suitable venue, either grass, artificial turf, or hard court.',
          <span>
            • The <a href="https://www.roundnetfederation.org/official-rules" target="_blank" rel="noopener noreferrer">IRF rule set</a> applies at the tournament.
          </span>,
          <span>
            • At least 3 <a href="https://www.roundnetfederation.org/observer-guidelines" target="_blank" rel="noopener noreferrer">IRF/ARF-certified</a> observers attend the tournament.
          </span>,
          <span>
            <a href="http://bit.ly/arfobserver" target="_blank" rel="noopener noreferrer">Please apply here.</a>
          </span>,
          '• The tournament sign-up system is available in English, other languages can be the main language, but to be certified, an English version has to be available.',
          '• Using standard Roundnet equipment approved by the ARF.',
          '• The tournament uses Fwango.io, a user-friendly online platform for tournament management.',
          <span>
            • The tournament proposal shall be submitted at least 1 month before the start date of the tournament via <a href="http://bit.ly/rpsanction" target="_blank" rel="noopener noreferrer">google form</a>
          </span>,
          '• RP will schedule a virtual meeting with the organizers to finalize the proposal.',
          <span>
            • The tournament should use the <a href="http://bit.ly/rpfeedbacks" target="_blank" rel="noopener noreferrer">rp evaluation form</a> and results shall be submitted not later than 1 week after the tournament.
          </span>,
          '• RP reserves the right to approve or decline a tournament to be sanctioned.',
          '• RP reserves the right to revoke sanctioned events if requirements are not met.',
          '• Tournament provides ranking points, and players have to be seeded based on RP power ranking points.'
        ],
        image: picture3,
      },
    ];

  useEffect(() => {
    let interval = null;
    if (!isHovered) {
      interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % content.length);
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isHovered, content.length]);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % content.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + content.length) % content.length);
  };

  return (
    <div className="sanction-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <h1 className="title">Sanctioned Tournament</h1>
      <div className="slider">
        {content.map((slide, index) => (
          <div className={`slide ${index === currentSlide ? 'active' : ''}`} key={index}>
            <div className="left-side">
              <h2>{slide.title}</h2>
              {slide.paragraphs.map((paragraph, index) => (
                <p key={index} style={{ marginTop: slide.paragraphMarginTop, lineHeight: slide.paragraphLineHeight }}>{paragraph}</p>
              ))}
            </div>
              <div className="arrow-container">
                <button className="prev-button" onClick={handlePrevSlide}>
                  <FaArrowLeft className="arrow-icon"  style={{ fill: 'black'}}/>
                </button>
                <button className="next-button" onClick={handleNextSlide}>
                  <FaArrowRight className="arrow-icon" style={{ fill: 'black'}} />
                </button>
              </div>
            <div className="right-side">
              <img src={slide.image} alt={slide.title} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Sanction;
