import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import video from "../assets/reel_1.mp4";
import 'intersection-observer'; // Import the polyfill

const About = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);

  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVideoMouseEnter = () => {
    setShowControls(true);
  };

  const handleVideoMouseLeave = () => {
    setShowControls(false);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust this threshold as per your needs (0.5 means 50% visibility)
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    }, options);

    if (videoContainerRef.current) {
      observer.observe(videoContainerRef.current);
    }

    return () => {
      if (videoContainerRef.current) {
        observer.unobserve(videoContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (isVisible) {
      videoElement.play().catch((error) => {
        console.error('Autoplay failed:', error);
      });
    } else {
      videoElement.pause();
    }
  }, [isVisible]);

  useEffect(() => {
    // Hide controls after 3 seconds when video is playing
    if (isPlaying && showControls) {
      const timer = setTimeout(() => {
        setShowControls(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isPlaying, showControls]);

  return (
    <div className="home-first">
      <div data-aos="fade-up-right" className="home-content">
        <h2 className="home-header">
          Welcome to Roundnet Philippines
        </h2>
        <div className="home-list">
          <div className="home-note">
            <div className="home-point"></div>
            <p className="home-text16">
              Roundnet Philippines is a passionate community dedicated to promoting and growing the sport of roundnet (commonly known as spikeball) in the Philippines. We aim to provide a platform for players of all levels to come together, compete, and have fun.
            </p>
          </div>
          <div className="home-note1">
            <div className="home-point01"></div>
            <p className="home-text17">
              Our mission is to spread awareness about roundnet and its benefits, including improved fitness, teamwork, and sportsmanship. Through organizing events, tournaments, and training sessions, we strive to create a vibrant roundnet community across the country.
            </p>
          </div>
        </div>
        <Link to="/about" className="learn-more-link" onClick={handleScrollToTop}>
          Learn More
          <span className="arrow-right"></span>
        </Link>
      </div>
      <div
        ref={videoContainerRef}
        className="video-container"
        onMouseEnter={handleVideoMouseEnter}
        onMouseLeave={handleVideoMouseLeave}
      >
        <video
          ref={videoRef}
          className="home-image05"
          onClick={handleVideoClick}
          loop
          autoPlay
          playsInline // Improve mobile compatibility
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className={`play-pause-icon ${showControls ? 'visible' : 'hidden'}`}
          onClick={handleVideoClick}
        >
          {isPlaying ? <span style={{ opacity: 0.6, color: 'black' }}>&#9658;</span> : <span style={{ opacity: 0.6, color: 'black' }}>&#10074;&#10074;</span>}
        </div>
      </div>
    </div>
  );
};

export default About;
