import "../scss/index.scss";
import { Footer, Home, ScrollToTop } from "../components";
import About from "../components/About";
import Event from "../components/Event";
import Teams from "../components/Teams";
import Subscribe from "../components/SubscribeNewsletter";
import ContactForm from "../components/ContactForm";
import GalleryPage from "../components/GalleryPage";
import Buy from "../components/Buy";
import Transition from "../Transition";

function Landing() {

  return (
    <div className="App">
      <ScrollToTop />
      <Home />
      <About />
      <Event />
      <Teams />
      <GalleryPage />
      <Buy />
      <Subscribe />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Transition(Landing);
