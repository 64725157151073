import React from 'react';
import Navbar from '../Navbar';

const HeaderRanking = () => {
  return (
    <header className="headers header-rankings">
      <Navbar />
      <h1 className="title">Rankings</h1>
    </header>
  );
};

export default HeaderRanking;