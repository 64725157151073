import React, { useState, useEffect } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

function ImageGallery({ images, viewImage, isImageLoaded }) {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
      <Masonry gutter="20px">
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            className="gallery-image"
            alt=""
            onClick={() => viewImage(image, i)}
          />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
}

function PhotoGallery() {
  const [data, setData] = useState({ img: '', i: 0, animation: '' });
  const [loading, setLoading] = useState(true); // State for loading skeleton
  const [loadedImages, setLoadedImages] = useState([]); // State to track loaded images

  const viewImage = (img, i) => {
    setData({ img, i });
  };

  const imgAction = (action) => {
    let i = data.i;

    if (action === 'next-img') {
      setData({ img: images[i + 1], i: i + 1, animation: 'slide-left' });
    } else if (action === 'prev-img') {
      setData({ img: images[i - 1], i: i - 1, animation: 'slide-right' });
    } else {
      setData({ img: '', i: 0, animation: '' });
    }
  };

  const images = [
    require('../assets/gallery/1.jpg'),
    require('../assets/gallery/2.jpg'),
    require('../assets/gallery/3.jpg'),
    require('../assets/gallery/4.jpg'),
    require('../assets/gallery/5.jpg'),
    require('../assets/gallery/6.jpg'),
    require('../assets/gallery/7.jpg'),
    require('../assets/gallery/8.jpg'),
    require('../assets/gallery/9.jpg'),
    require('../assets/gallery/10.jpg'),
    require('../assets/gallery/11.jpg'),
    require('../assets/gallery/12.jpg'),
    require('../assets/gallery/13.jpg'),
    require('../assets/gallery/14.jpg'),
    require('../assets/gallery/15.jpg'),
    require('../assets/gallery/16.jpg'),
    require('../assets/gallery/17.jpg'),
    require('../assets/gallery/18.jpg'),
    require('../assets/gallery/19.jpg'),
    require('../assets/gallery/20.jpg'),
    require('../assets/gallery/21.jpg'),
    require('../assets/gallery/22.jpg'),
    require('../assets/gallery/23.jpg'),
    require('../assets/gallery/24.jpg'),
    require('../assets/gallery/25.jpg'),
    require('../assets/gallery/26.jpg'),
    require('../assets/gallery/27.jpg'),
    require('../assets/gallery/28.jpg'),
    require('../assets/gallery/29.jpg'),
    require('../assets/gallery/30.jpg'),
    require('../assets/gallery/31.jpg'),
    require('../assets/gallery/32.jpg'),
    require('../assets/gallery/33.jpg'),
    require('../assets/gallery/34.jpg'),
    require('../assets/gallery/35.jpg'),
    require('../assets/gallery/36.jpg'),
    require('../assets/gallery/37.jpg'),
    require('../assets/gallery/38.jpg'),
    require('../assets/gallery/39.jpg'),
    require('../assets/gallery/40.jpg'),
    require('../assets/gallery/41.jpg'),
    require('../assets/gallery/42.jpg'),
    require('../assets/gallery/43.jpg'),
    require('../assets/gallery/44.jpg'),
    require('../assets/gallery/45.jpg'),
    require('../assets/gallery/46.jpg'),
    require('../assets/gallery/47.jpg'),
    require('../assets/gallery/48.jpg'),
    require('../assets/gallery/49.jpg'),
    require('../assets/gallery/50.jpg'),
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide the skeleton after a short delay (simulating data fetching/loading)
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  const isImageLoaded = (index) => {
    return loadedImages.includes(index);
  };

  return (
    <div className="photo-gallery">
      {data.img && (
        <div className={`fullscreen-overlay ${data.animation}-enter`}>
          <button onClick={() => imgAction()} className="close-button">
            ✖
          </button>
          <button onClick={() => imgAction('prev-img')} className="nav-button prev-button">
            ← Previous
          </button>
          <img
            src={data.img}
            className={`fullscreen-image ${data.animation}-enter`}
            alt=""
            onAnimationEnd={() => setData((prevState) => ({ ...prevState, animation: '' }))}
          />
          <button onClick={() => imgAction('next-img')} className="nav-button next-button">
            Next →
          </button>
        </div>
      )}
      <div className="gallery-container">
        {loading ? (
          // Skeleton loading animation
          <div className="skeleton-loading">
            {images.map((_, i) => (
              <div
                key={i}
                className={`skeleton-image ${isImageLoaded(i) ? 'loaded' : ''}`}
              ></div>
            ))}
          </div>
        ) : (
          // Render the actual image gallery
          <ImageGallery images={images} viewImage={viewImage} isImageLoaded={isImageLoaded} />
        )}
      </div>
    </div>
  );
}

export default PhotoGallery;
