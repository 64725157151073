import React from 'react';
import Navbar from '../Navbar';

const HeaderTeam = () => {
  return (
    <header className="headers header-team">
      <Navbar />
      <h1 className="title">Membership</h1>
    </header>
  );
};

export default HeaderTeam;