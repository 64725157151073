import React from 'react';
import logo1 from '../assets/clubLogo/clublogo.png';
import logo2 from '../assets/clubLogo/bayawanLogo.png';
import logo3 from '../assets/clubLogo/frcLogo.png';
import logo4 from '../assets/clubLogo/valleyLogo.png';
import logo5 from '../assets/clubLogo/clublogo.png';
import logo6 from '../assets/clubLogo/zagubangLogo.png';
import logo7 from '../assets/clubLogo/clublogo.png';
import logo8 from '../assets/clubLogo/crawlersLogo.png';
import logo9 from '../assets/clubLogo/clublogo.png';
import logo10 from '../assets/clubLogo/clublogo.png';
import logo11 from '../assets/clubLogo/clublogo.png';
import logo12 from '../assets/clubLogo/vanguardsLogo.png';
import logo13 from '../assets/clubLogo/squidLogo.png';
import logo14 from '../assets/clubLogo/nookiesLogo.png';

import groupPhoto1 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto2 from '../assets/groupPhoto/bayawanPhoto.png';
import groupPhoto3 from '../assets/groupPhoto/frcPhoto.png';
import groupPhoto4 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto5 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto6 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto7 from '../assets/groupPhoto/trafficPhoto.png';
import groupPhoto8 from '../assets/groupPhoto/crawlersPhoto.png';
import groupPhoto9 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto10 from '../assets/groupPhoto/katipunerosPhoto.png';
import groupPhoto11 from '../assets/groupPhoto/meatballsPhoto.png';
import groupPhoto12 from '../assets/groupPhoto/groupphoto.png';
import groupPhoto13 from '../assets/groupPhoto/squidPhoto.png';
import groupPhoto14 from '../assets/groupPhoto/groupphoto.png';

const clubData = [
  {
    title: 'MB Cove',
    subtitle: 'January 14, 2023',
    logo: logo1,
    website: 'https://www.phdummyexample123.com',
    photo: groupPhoto1,
    description: '123 Dummy Street, Manila, Philippines',
  },
  {
    title: 'Meatballs',
    subtitle: 'June 01, 2023',
    logo: logo11,
    website: 'https://www.phdummyexample123.com',
    photo: groupPhoto11,
    description: '123 Palm Street, Makati City, Metro Manila, Philippines',
  },
  {
    title: 'Squid Roundnet ',
    subtitle: 'June 09, 2023',
    logo: logo13,
    website: 'https://www.instagram.com/squid_roundnet/',
    photo: groupPhoto13,
    description: '123 Palm Street, Makati City, Metro Manila, Philippines',
  },
  {
    title: 'Nookies Roundnet Club',
    subtitle: 'June 09, 2023',
    logo: logo14,
    website: 'https://www.instagram.com/nookies_roundnet/',
    photo: groupPhoto14,
    description: '123 Palm Street, Makati City, Metro Manila, Philippines',
  },
];

const PendingClubs = () => {
  return (
    <>
      <div className="header-club">
        <div className="title-about">Pending Member Clubs</div>
      </div>
      {clubData.map((club, index) => (
        <div className="container-club" key={index}>
          <h1 className="title-club">{club.title}</h1>
          <h2 className="subtitle-club">{club.subtitle}</h2>
          <div className="content-club">
            <div className="left-image-club">
              <img src={club.logo} alt="Left Image" />
              <a href={club.website} target="_blank" className="description-club">
                {club.website}
              </a>
            </div>
            <div className="right-image-club">
              <img src={club.photo} alt="Right Image" />
              <p className="description-club">{club.description}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PendingClubs;
